import { useEffect, useState } from "react";
import useAddOrder from "../../hooks/addOrder";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons } from "../../redux/actions/CouponAction";
import { ToastContainer, toast } from "react-toastify";

import OrderListSkeleton from "../Skeleton/OrderSkeleton";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
import SmallLoader from "../Loader/SmallLoader";
import useHandlePagintationPlayers from "../../hooks/handlePaginationPlayer";
import useHandleRegisterClinic from "../../hooks/useHandleRegisterClinic";
import useSignUpAsWorkWithUs from "../../hooks/signUpWork";
import useSignUpAsPartner from "../../hooks/signUpPartner";
import { useParams } from "react-router-dom";
// import { registerAsTournament } from "../../redux/actions/TournamentAction";
import useSignUpAsVolunteer from "../../hooks/signUpVolunteer";
import { registerAsTrainingProgram } from "../../redux/actions/TrainingProgramAction";
import { registerAsCamp } from "../../redux/actions/CampAction";
// import { registerAsClinic } from "../../redux/actions/ClinicAction";
import { useNavigate } from "react-router-dom";
import useAuthInfo from "../../hooks/getUser";
import { MdOutlineDelete } from "react-icons/md";

const SessionOrderBox = ({ items, loading, removeFromCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registerVolunteerFunc, loading: creatingVolunteer } =
    useSignUpAsVolunteer();
  const { userId } = useAuthInfo();
  const [isLoading, setLoading] = useState(false);
  const { pageName } = useParams();
  const { signUpWorkWithUsFunc, loading: creating } = useSignUpAsWorkWithUs();
  const { signUpPartnerFunc, loading: creatingPartner } = useSignUpAsPartner();

  console.log("apage", pageName);

  const {
    registerAsPlayer,

    registerAsTournament,
    loading: playerLoading,
  } = useHandlePagintationPlayers();
  const { registerAsClinic, loading: clinicLoading } =
    useHandleRegisterClinic();
  const { user } = useSelector((state) => state.user);
  const calculateTotal = () => {
    let total = 0;

    items.forEach((item) => {
      total = +item?.price;
    });

    return total;
  };

  const data = localStorage.getItem("formData");
  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
  const signup = async () => {
    if (!data) {
      toast.error("invalid data");
      return;
      // return navigate("/");
    }
    let sessionsIds = [];

    items?.forEach((item) => {
      sessionsIds.push(item.id);
    });

    const parsedData = JSON.parse(data); // Parse the string back to an object
    const dataSend = {
      ...parsedData, // Merge the parsed object with other data
      sessionsIds,
      courseID: parsedData.courseID,
      userId,
    };

    console.log("formData", dataSend);
    try {
      console.log("data", parsedData);
      if (pageName === "player") {
        await registerAsPlayer(dataSend);
      } else if (pageName === "volunteer") {
        await registerVolunteerFunc(dataSend);
      } else if (pageName === "workwithus") {
        await signUpWorkWithUsFunc(dataSend);
      } else if (pageName === "partner") {
        await signUpPartnerFunc(dataSend);
      } else if (pageName === "clinic") {
        dispatch(registerAsClinic(dataSend));
      } else if (pageName === "tournament") {
        dispatch(registerAsTournament(dataSend, user));
      } else if (pageName === "trainingprogram") {
        dispatch(registerAsTrainingProgram(dataSend, user));
      } else if (pageName === "camp") {
        dispatch(registerAsCamp(dataSend, user));
      }
      // localStorage.removeItem("formData");
    } catch (error) {
      console.log(error);
    }
  };

  const buy = async () => {
    if (!items?.length && pageName !== "trainingprogram") {
      toast.error("Add items to your order");
      return false;
    } else {
      setLoading(true);
      await signup();
      setLoading(false);
    }
  };

  return (
    <>
      <div className="p-2 mt-4 rounded-md">
        <ToastContainer />

        <p className="text-sm uppercase font-bold">your shopping cart</p>
        {loading ? (
          <>
            <OrderListSkeleton />
            <OrderListSkeleton />
          </>
        ) : (
          <ul className="space-y-4">
            {items?.length === 0 && (
              <p>
                You have no sessions selected. Get started by adding session on
                the left.
              </p>
            )}
            {items?.length > 0 &&
              items?.map((item) => (
                <li
                  key={item?.id}
                  className="flex justify-between items-center border-b border-gray-300 pb-2"
                >
                  <span className="text-sm">{item?.title}</span>

                  <span className="font-bold text-sm">${item?.price}</span>
                  <button
                    className="cursor-pointer p-2 hover:bg-gray-200 rounded-full transition-all ease-in-out duration-500"
                    onClick={() => removeFromCart(item)}
                  >
                    <MdOutlineDelete />
                  </button>
                </li>
              ))}

            <br />
            <br />

            <li className="flex justify-between items-center border-b border-gray-300 pb-2">
              <span>Order Total:</span>
              <span className="font-bold">${calculateTotal()}</span>
            </li>
          </ul>
        )}
        {loading || isLoading ? (
          <div className="mt-2">
            <SmallLoader />
          </div>
        ) : (
          <div className="mt-4">
            <button
              onClick={buy}
              disabled={loading || isLoading}
              className="bg-[#fec749] w-full px-4 py-2 rounded-md "
            >
              CONTINUE
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SessionOrderBox;
