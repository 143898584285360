/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      title
      date
      brief
      img_url
      video_url
      youtube_url
      view_count
      content_text
      content_img_url
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      title
      date
      brief
      img_url
      video_url
      youtube_url
      view_count
      content_text
      content_img_url
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      title
      date
      brief
      img_url
      video_url
      youtube_url
      view_count
      content_text
      content_img_url
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategories = /* GraphQL */ `
  mutation CreateCategories(
    $input: CreateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    createCategories(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories(
    $input: UpdateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    updateCategories(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategories = /* GraphQL */ `
  mutation DeleteCategories(
    $input: DeleteCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    deleteCategories(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      userid
      cartsItems
      leagueItems
      totalItems
      totalAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      userid
      cartsItems
      leagueItems
      totalItems
      totalAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      userid
      cartsItems
      leagueItems
      totalItems
      totalAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCartItem = /* GraphQL */ `
  mutation CreateCartItem(
    $input: CreateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    createCartItem(input: $input, condition: $condition) {
      id
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCartItem = /* GraphQL */ `
  mutation UpdateCartItem(
    $input: UpdateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    updateCartItem(input: $input, condition: $condition) {
      id
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCartItem = /* GraphQL */ `
  mutation DeleteCartItem(
    $input: DeleteCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    deleteCartItem(input: $input, condition: $condition) {
      id
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeagueCart = /* GraphQL */ `
  mutation CreateLeagueCart(
    $input: CreateLeagueCartInput!
    $condition: ModelLeagueCartConditionInput
  ) {
    createLeagueCart(input: $input, condition: $condition) {
      id
      userid
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeagueCart = /* GraphQL */ `
  mutation UpdateLeagueCart(
    $input: UpdateLeagueCartInput!
    $condition: ModelLeagueCartConditionInput
  ) {
    updateLeagueCart(input: $input, condition: $condition) {
      id
      userid
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeagueCart = /* GraphQL */ `
  mutation DeleteLeagueCart(
    $input: DeleteLeagueCartInput!
    $condition: ModelLeagueCartConditionInput
  ) {
    deleteLeagueCart(input: $input, condition: $condition) {
      id
      userid
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      title
      date
      location
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeague = /* GraphQL */ `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      title
      date
      location
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeague = /* GraphQL */ `
  mutation DeleteLeague(
    $input: DeleteLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    deleteLeague(input: $input, condition: $condition) {
      id
      title
      date
      location
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      placed_date
      shipped_date
      delivered_date
      cancelled_date
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      cartItemId
      finalPrice
      code
      couponValue
      amount
      sessionId
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      placed_date
      shipped_date
      delivered_date
      cancelled_date
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      cartItemId
      finalPrice
      code
      couponValue
      amount
      sessionId
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      placed_date
      shipped_date
      delivered_date
      cancelled_date
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      cartItemId
      finalPrice
      code
      couponValue
      amount
      sessionId
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBookedEvent = /* GraphQL */ `
  mutation CreateBookedEvent(
    $input: CreateBookedEventInput!
    $condition: ModelBookedEventConditionInput
  ) {
    createBookedEvent(input: $input, condition: $condition) {
      id
      userid
      placed_date
      eventDate
      eventEnded
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      leagueCartId
      quantity
      finalPrice
      sessionId
      code
      couponValue
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBookedEvent = /* GraphQL */ `
  mutation UpdateBookedEvent(
    $input: UpdateBookedEventInput!
    $condition: ModelBookedEventConditionInput
  ) {
    updateBookedEvent(input: $input, condition: $condition) {
      id
      userid
      placed_date
      eventDate
      eventEnded
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      leagueCartId
      quantity
      finalPrice
      sessionId
      code
      couponValue
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBookedEvent = /* GraphQL */ `
  mutation DeleteBookedEvent(
    $input: DeleteBookedEventInput!
    $condition: ModelBookedEventConditionInput
  ) {
    deleteBookedEvent(input: $input, condition: $condition) {
      id
      userid
      placed_date
      eventDate
      eventEnded
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      leagueCartId
      quantity
      finalPrice
      sessionId
      code
      couponValue
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      leagueId
      scoreID
      agegroup
      location
      city
      players {
        items {
          id
          userId
          isVerified
          league
          agegroup
          ext
          participantName
          cityOfRegistration
          scoreID
          teamID
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          jerseySize
          jerseyNo
          sessionId
          seasonIds
          schoolName
          transactionStatus
          memberExpire
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          isFirstYearPlaying
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          document
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      leagueId
      scoreID
      agegroup
      location
      city
      players {
        items {
          id
          userId
          isVerified
          league
          agegroup
          ext
          participantName
          cityOfRegistration
          scoreID
          teamID
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          jerseySize
          jerseyNo
          sessionId
          seasonIds
          schoolName
          transactionStatus
          memberExpire
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          isFirstYearPlaying
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          document
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      leagueId
      scoreID
      agegroup
      location
      city
      players {
        items {
          id
          userId
          isVerified
          league
          agegroup
          ext
          participantName
          cityOfRegistration
          scoreID
          teamID
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          jerseySize
          jerseyNo
          sessionId
          seasonIds
          schoolName
          transactionStatus
          memberExpire
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          isFirstYearPlaying
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          document
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWorkWithUs = /* GraphQL */ `
  mutation CreateWorkWithUs(
    $input: CreateWorkWithUsInput!
    $condition: ModelWorkWithUsConditionInput
  ) {
    createWorkWithUs(input: $input, condition: $condition) {
      id
      userId
      participantName
      email
      applyingFor
      preferredCityOfEvent
      resume
      dateOfBirth
      isAdult
      ext
      shirtSize
      isMemberOfOtherClub
      clubName
      gender
      secondaryPersonName
      transactionStatus
      sessionId
      message
      memberExpire
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorkWithUs = /* GraphQL */ `
  mutation UpdateWorkWithUs(
    $input: UpdateWorkWithUsInput!
    $condition: ModelWorkWithUsConditionInput
  ) {
    updateWorkWithUs(input: $input, condition: $condition) {
      id
      userId
      participantName
      email
      applyingFor
      preferredCityOfEvent
      resume
      dateOfBirth
      isAdult
      ext
      shirtSize
      isMemberOfOtherClub
      clubName
      gender
      secondaryPersonName
      transactionStatus
      sessionId
      message
      memberExpire
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorkWithUs = /* GraphQL */ `
  mutation DeleteWorkWithUs(
    $input: DeleteWorkWithUsInput!
    $condition: ModelWorkWithUsConditionInput
  ) {
    deleteWorkWithUs(input: $input, condition: $condition) {
      id
      userId
      participantName
      email
      applyingFor
      preferredCityOfEvent
      resume
      dateOfBirth
      isAdult
      ext
      shirtSize
      isMemberOfOtherClub
      clubName
      gender
      secondaryPersonName
      transactionStatus
      sessionId
      message
      memberExpire
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVolunteer = /* GraphQL */ `
  mutation CreateVolunteer(
    $input: CreateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    createVolunteer(input: $input, condition: $condition) {
      participantName
      event
      email
      userId
      preferredCity
      volunteerSpecialty
      message
      msg
      familyMemberName
      ext
      transactionStatus
      sessionId
      memberExpire
      dateOfBirth
      isAdult
      gender
      shirtSize
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      agegroup
      phoneNumber
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVolunteer = /* GraphQL */ `
  mutation UpdateVolunteer(
    $input: UpdateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    updateVolunteer(input: $input, condition: $condition) {
      participantName
      event
      email
      userId
      preferredCity
      volunteerSpecialty
      message
      msg
      familyMemberName
      ext
      transactionStatus
      sessionId
      memberExpire
      dateOfBirth
      isAdult
      gender
      shirtSize
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      agegroup
      phoneNumber
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVolunteer = /* GraphQL */ `
  mutation DeleteVolunteer(
    $input: DeleteVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    deleteVolunteer(input: $input, condition: $condition) {
      participantName
      event
      email
      userId
      preferredCity
      volunteerSpecialty
      message
      msg
      familyMemberName
      ext
      transactionStatus
      sessionId
      memberExpire
      dateOfBirth
      isAdult
      gender
      shirtSize
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      agegroup
      phoneNumber
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic(
    $input: CreateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    createClinic(input: $input, condition: $condition) {
      id
      userId
      sessionId
      type
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      courseID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      schoolName
      paymentStatus
      isFirstYearPlaying
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      agegroup
      phoneNumber
      appointmentId
      appointmentScheduleId
      used
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic(
    $input: UpdateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    updateClinic(input: $input, condition: $condition) {
      id
      userId
      sessionId
      type
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      courseID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      schoolName
      paymentStatus
      isFirstYearPlaying
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      agegroup
      phoneNumber
      appointmentId
      appointmentScheduleId
      used
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinic = /* GraphQL */ `
  mutation DeleteClinic(
    $input: DeleteClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    deleteClinic(input: $input, condition: $condition) {
      id
      userId
      sessionId
      type
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      courseID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      schoolName
      paymentStatus
      isFirstYearPlaying
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      agegroup
      phoneNumber
      appointmentId
      appointmentScheduleId
      used
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinicCourse = /* GraphQL */ `
  mutation CreateClinicCourse(
    $input: CreateClinicCourseInput!
    $condition: ModelClinicCourseConditionInput
  ) {
    createClinicCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinicCourse = /* GraphQL */ `
  mutation UpdateClinicCourse(
    $input: UpdateClinicCourseInput!
    $condition: ModelClinicCourseConditionInput
  ) {
    updateClinicCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinicCourse = /* GraphQL */ `
  mutation DeleteClinicCourse(
    $input: DeleteClinicCourseInput!
    $condition: ModelClinicCourseConditionInput
  ) {
    deleteClinicCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCamp = /* GraphQL */ `
  mutation CreateCamp(
    $input: CreateCampInput!
    $condition: ModelCampConditionInput
  ) {
    createCamp(input: $input, condition: $condition) {
      id
      userId
      sessionId
      courseID
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      campID
      profile
      campName
      message
      dateOfBirth
      isAdult
      grade
      isFirstYearPlaying
      transactionStatus
      memberExpire
      email
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      experience
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCamp = /* GraphQL */ `
  mutation UpdateCamp(
    $input: UpdateCampInput!
    $condition: ModelCampConditionInput
  ) {
    updateCamp(input: $input, condition: $condition) {
      id
      userId
      sessionId
      courseID
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      campID
      profile
      campName
      message
      dateOfBirth
      isAdult
      grade
      isFirstYearPlaying
      transactionStatus
      memberExpire
      email
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      experience
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCamp = /* GraphQL */ `
  mutation DeleteCamp(
    $input: DeleteCampInput!
    $condition: ModelCampConditionInput
  ) {
    deleteCamp(input: $input, condition: $condition) {
      id
      userId
      sessionId
      courseID
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      campID
      profile
      campName
      message
      dateOfBirth
      isAdult
      grade
      isFirstYearPlaying
      transactionStatus
      memberExpire
      email
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      experience
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCampEvent = /* GraphQL */ `
  mutation CreateCampEvent(
    $input: CreateCampEventInput!
    $condition: ModelCampEventConditionInput
  ) {
    createCampEvent(input: $input, condition: $condition) {
      id
      cityOfRegistration
      name
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCampEvent = /* GraphQL */ `
  mutation UpdateCampEvent(
    $input: UpdateCampEventInput!
    $condition: ModelCampEventConditionInput
  ) {
    updateCampEvent(input: $input, condition: $condition) {
      id
      cityOfRegistration
      name
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCampEvent = /* GraphQL */ `
  mutation DeleteCampEvent(
    $input: DeleteCampEventInput!
    $condition: ModelCampEventConditionInput
  ) {
    deleteCampEvent(input: $input, condition: $condition) {
      id
      cityOfRegistration
      name
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCampCard = /* GraphQL */ `
  mutation CreateCampCard(
    $input: CreateCampCardInput!
    $condition: ModelCampCardConditionInput
  ) {
    createCampCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCampCard = /* GraphQL */ `
  mutation UpdateCampCard(
    $input: UpdateCampCardInput!
    $condition: ModelCampCardConditionInput
  ) {
    updateCampCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCampCard = /* GraphQL */ `
  mutation DeleteCampCard(
    $input: DeleteCampCardInput!
    $condition: ModelCampCardConditionInput
  ) {
    deleteCampCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCampCourse = /* GraphQL */ `
  mutation CreateCampCourse(
    $input: CreateCampCourseInput!
    $condition: ModelCampCourseConditionInput
  ) {
    createCampCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCampCourse = /* GraphQL */ `
  mutation UpdateCampCourse(
    $input: UpdateCampCourseInput!
    $condition: ModelCampCourseConditionInput
  ) {
    updateCampCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCampCourse = /* GraphQL */ `
  mutation DeleteCampCourse(
    $input: DeleteCampCourseInput!
    $condition: ModelCampCourseConditionInput
  ) {
    deleteCampCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSignUpEvent = /* GraphQL */ `
  mutation CreateSignUpEvent(
    $input: CreateSignUpEventInput!
    $condition: ModelSignUpEventConditionInput
  ) {
    createSignUpEvent(input: $input, condition: $condition) {
      id
      userId
      league
      cityName
      description
      transactionStatus
      memberExpire
      sessionId
      email
      dateOfBirth
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSignUpEvent = /* GraphQL */ `
  mutation UpdateSignUpEvent(
    $input: UpdateSignUpEventInput!
    $condition: ModelSignUpEventConditionInput
  ) {
    updateSignUpEvent(input: $input, condition: $condition) {
      id
      userId
      league
      cityName
      description
      transactionStatus
      memberExpire
      sessionId
      email
      dateOfBirth
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSignUpEvent = /* GraphQL */ `
  mutation DeleteSignUpEvent(
    $input: DeleteSignUpEventInput!
    $condition: ModelSignUpEventConditionInput
  ) {
    deleteSignUpEvent(input: $input, condition: $condition) {
      id
      userId
      league
      cityName
      description
      transactionStatus
      memberExpire
      sessionId
      email
      dateOfBirth
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTournament = /* GraphQL */ `
  mutation CreateTournament(
    $input: CreateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    createTournament(input: $input, condition: $condition) {
      id
      userId
      sessionId
      isVerified
      ext
      participantName
      tournamentId
      team
      teamID
      matches {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      isFirstYearPlaying
      jerseyNo
      transactionStatus
      memberExpire
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTournament = /* GraphQL */ `
  mutation UpdateTournament(
    $input: UpdateTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    updateTournament(input: $input, condition: $condition) {
      id
      userId
      sessionId
      isVerified
      ext
      participantName
      tournamentId
      team
      teamID
      matches {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      isFirstYearPlaying
      jerseyNo
      transactionStatus
      memberExpire
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTournament = /* GraphQL */ `
  mutation DeleteTournament(
    $input: DeleteTournamentInput!
    $condition: ModelTournamentConditionInput
  ) {
    deleteTournament(input: $input, condition: $condition) {
      id
      userId
      sessionId
      isVerified
      ext
      participantName
      tournamentId
      team
      teamID
      matches {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      isFirstYearPlaying
      jerseyNo
      transactionStatus
      memberExpire
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTrainingProgram = /* GraphQL */ `
  mutation CreateTrainingProgram(
    $input: CreateTrainingProgramInput!
    $condition: ModelTrainingProgramConditionInput
  ) {
    createTrainingProgram(input: $input, condition: $condition) {
      id
      userId
      isVerified
      ext
      participantName
      type
      courseID
      teamId
      teamName
      cityOfRegistration
      profile
      message
      dateOfBirth
      transactionStatus
      sessionId
      memberExpire
      isAdult
      email
      paymentStatus
      clubName
      signature
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTrainingProgram = /* GraphQL */ `
  mutation UpdateTrainingProgram(
    $input: UpdateTrainingProgramInput!
    $condition: ModelTrainingProgramConditionInput
  ) {
    updateTrainingProgram(input: $input, condition: $condition) {
      id
      userId
      isVerified
      ext
      participantName
      type
      courseID
      teamId
      teamName
      cityOfRegistration
      profile
      message
      dateOfBirth
      transactionStatus
      sessionId
      memberExpire
      isAdult
      email
      paymentStatus
      clubName
      signature
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTrainingProgram = /* GraphQL */ `
  mutation DeleteTrainingProgram(
    $input: DeleteTrainingProgramInput!
    $condition: ModelTrainingProgramConditionInput
  ) {
    deleteTrainingProgram(input: $input, condition: $condition) {
      id
      userId
      isVerified
      ext
      participantName
      type
      courseID
      teamId
      teamName
      cityOfRegistration
      profile
      message
      dateOfBirth
      transactionStatus
      sessionId
      memberExpire
      isAdult
      email
      paymentStatus
      clubName
      signature
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      organization
      reason
      userId
      number
      email
      message
      sessionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      organization
      reason
      userId
      number
      email
      message
      sessionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      organization
      reason
      userId
      number
      email
      message
      sessionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      userid
      addresses {
        city
        country
        pincode
        address
        phonenumber
        secondaryPhonenumber
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      userid
      addresses {
        city
        country
        pincode
        address
        phonenumber
        secondaryPhonenumber
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      userid
      addresses {
        city
        country
        pincode
        address
        phonenumber
        secondaryPhonenumber
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      event
      eventId
      eventImage
      matchNumber
      round
      teams {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      firstTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      secondTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      tournament
      winner
      runnerUp
      agegroup
      courtNo
      record
      ppg
      rpe
      apg
      ranking
      city
      date
      time
      playerDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      firstTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      secondTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      eventStatus
      roundId
      eventsId
      winnerId
      player {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      event
      eventId
      eventImage
      matchNumber
      round
      teams {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      firstTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      secondTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      tournament
      winner
      runnerUp
      agegroup
      courtNo
      record
      ppg
      rpe
      apg
      ranking
      city
      date
      time
      playerDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      firstTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      secondTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      eventStatus
      roundId
      eventsId
      winnerId
      player {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      event
      eventId
      eventImage
      matchNumber
      round
      teams {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      firstTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      secondTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      tournament
      winner
      runnerUp
      agegroup
      courtNo
      record
      ppg
      rpe
      apg
      ranking
      city
      date
      time
      playerDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      firstTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      secondTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      eventStatus
      roundId
      eventsId
      winnerId
      player {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscribedUsers = /* GraphQL */ `
  mutation CreateSubscribedUsers(
    $input: CreateSubscribedUsersInput!
    $condition: ModelSubscribedUsersConditionInput
  ) {
    createSubscribedUsers(input: $input, condition: $condition) {
      email
      exported
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscribedUsers = /* GraphQL */ `
  mutation UpdateSubscribedUsers(
    $input: UpdateSubscribedUsersInput!
    $condition: ModelSubscribedUsersConditionInput
  ) {
    updateSubscribedUsers(input: $input, condition: $condition) {
      email
      exported
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscribedUsers = /* GraphQL */ `
  mutation DeleteSubscribedUsers(
    $input: DeleteSubscribedUsersInput!
    $condition: ModelSubscribedUsersConditionInput
  ) {
    deleteSubscribedUsers(input: $input, condition: $condition) {
      email
      exported
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdvertisement = /* GraphQL */ `
  mutation CreateAdvertisement(
    $input: CreateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    createAdvertisement(input: $input, condition: $condition) {
      id
      lg_img_url
      md_img_url
      sm_img_url
      xs_img_url
      page
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdvertisement = /* GraphQL */ `
  mutation UpdateAdvertisement(
    $input: UpdateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    updateAdvertisement(input: $input, condition: $condition) {
      id
      lg_img_url
      md_img_url
      sm_img_url
      xs_img_url
      page
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdvertisement = /* GraphQL */ `
  mutation DeleteAdvertisement(
    $input: DeleteAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    deleteAdvertisement(input: $input, condition: $condition) {
      id
      lg_img_url
      md_img_url
      sm_img_url
      xs_img_url
      page
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDiscountCoupon = /* GraphQL */ `
  mutation CreateDiscountCoupon(
    $input: CreateDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    createDiscountCoupon(input: $input, condition: $condition) {
      id
      code
      couponValue
      discountOnUpto
      validFrom
      validTo
      isUserLimit
      uniqueProduct
      productCategories
      allProductsDiscount
      description
      isActive
      maxUsageLimit
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiscountCoupon = /* GraphQL */ `
  mutation UpdateDiscountCoupon(
    $input: UpdateDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    updateDiscountCoupon(input: $input, condition: $condition) {
      id
      code
      couponValue
      discountOnUpto
      validFrom
      validTo
      isUserLimit
      uniqueProduct
      productCategories
      allProductsDiscount
      description
      isActive
      maxUsageLimit
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDiscountCoupon = /* GraphQL */ `
  mutation DeleteDiscountCoupon(
    $input: DeleteDiscountCouponInput!
    $condition: ModelDiscountCouponConditionInput
  ) {
    deleteDiscountCoupon(input: $input, condition: $condition) {
      id
      code
      couponValue
      discountOnUpto
      validFrom
      validTo
      isUserLimit
      uniqueProduct
      productCategories
      allProductsDiscount
      description
      isActive
      maxUsageLimit
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeasonCharges = /* GraphQL */ `
  mutation CreateSeasonCharges(
    $input: CreateSeasonChargesInput!
    $condition: ModelSeasonChargesConditionInput
  ) {
    createSeasonCharges(input: $input, condition: $condition) {
      id
      title
      description
      price
      type
      startingDate
      endingDate
      agegroup
      location
      lastRegistrationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeasonCharges = /* GraphQL */ `
  mutation UpdateSeasonCharges(
    $input: UpdateSeasonChargesInput!
    $condition: ModelSeasonChargesConditionInput
  ) {
    updateSeasonCharges(input: $input, condition: $condition) {
      id
      title
      description
      price
      type
      startingDate
      endingDate
      agegroup
      location
      lastRegistrationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeasonCharges = /* GraphQL */ `
  mutation DeleteSeasonCharges(
    $input: DeleteSeasonChargesInput!
    $condition: ModelSeasonChargesConditionInput
  ) {
    deleteSeasonCharges(input: $input, condition: $condition) {
      id
      title
      description
      price
      type
      startingDate
      endingDate
      agegroup
      location
      lastRegistrationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPages = /* GraphQL */ `
  mutation CreatePages(
    $input: CreatePagesInput!
    $condition: ModelPagesConditionInput
  ) {
    createPages(input: $input, condition: $condition) {
      id
      title
      ads
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePages = /* GraphQL */ `
  mutation UpdatePages(
    $input: UpdatePagesInput!
    $condition: ModelPagesConditionInput
  ) {
    updatePages(input: $input, condition: $condition) {
      id
      title
      ads
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePages = /* GraphQL */ `
  mutation DeletePages(
    $input: DeletePagesInput!
    $condition: ModelPagesConditionInput
  ) {
    deletePages(input: $input, condition: $condition) {
      id
      title
      ads
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPricePages = /* GraphQL */ `
  mutation CreatePricePages(
    $input: CreatePricePagesInput!
    $condition: ModelPricePagesConditionInput
  ) {
    createPricePages(input: $input, condition: $condition) {
      id
      type
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePricePages = /* GraphQL */ `
  mutation UpdatePricePages(
    $input: UpdatePricePagesInput!
    $condition: ModelPricePagesConditionInput
  ) {
    updatePricePages(input: $input, condition: $condition) {
      id
      type
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePricePages = /* GraphQL */ `
  mutation DeletePricePages(
    $input: DeletePricePagesInput!
    $condition: ModelPricePagesConditionInput
  ) {
    deletePricePages(input: $input, condition: $condition) {
      id
      type
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinicCard = /* GraphQL */ `
  mutation CreateClinicCard(
    $input: CreateClinicCardInput!
    $condition: ModelClinicCardConditionInput
  ) {
    createClinicCard(input: $input, condition: $condition) {
      id
      title
      price
      order
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinicCard = /* GraphQL */ `
  mutation UpdateClinicCard(
    $input: UpdateClinicCardInput!
    $condition: ModelClinicCardConditionInput
  ) {
    updateClinicCard(input: $input, condition: $condition) {
      id
      title
      price
      order
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinicCard = /* GraphQL */ `
  mutation DeleteClinicCard(
    $input: DeleteClinicCardInput!
    $condition: ModelClinicCardConditionInput
  ) {
    deleteClinicCard(input: $input, condition: $condition) {
      id
      title
      price
      order
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTrainingProgramCard = /* GraphQL */ `
  mutation CreateTrainingProgramCard(
    $input: CreateTrainingProgramCardInput!
    $condition: ModelTrainingProgramCardConditionInput
  ) {
    createTrainingProgramCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTrainingProgramCard = /* GraphQL */ `
  mutation UpdateTrainingProgramCard(
    $input: UpdateTrainingProgramCardInput!
    $condition: ModelTrainingProgramCardConditionInput
  ) {
    updateTrainingProgramCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTrainingProgramCard = /* GraphQL */ `
  mutation DeleteTrainingProgramCard(
    $input: DeleteTrainingProgramCardInput!
    $condition: ModelTrainingProgramCardConditionInput
  ) {
    deleteTrainingProgramCard(input: $input, condition: $condition) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTrainingProgramCourse = /* GraphQL */ `
  mutation CreateTrainingProgramCourse(
    $input: CreateTrainingProgramCourseInput!
    $condition: ModelTrainingProgramCourseConditionInput
  ) {
    createTrainingProgramCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTrainingProgramCourse = /* GraphQL */ `
  mutation UpdateTrainingProgramCourse(
    $input: UpdateTrainingProgramCourseInput!
    $condition: ModelTrainingProgramCourseConditionInput
  ) {
    updateTrainingProgramCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTrainingProgramCourse = /* GraphQL */ `
  mutation DeleteTrainingProgramCourse(
    $input: DeleteTrainingProgramCourseInput!
    $condition: ModelTrainingProgramCourseConditionInput
  ) {
    deleteTrainingProgramCourse(input: $input, condition: $condition) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTournamentTeam = /* GraphQL */ `
  mutation CreateTournamentTeam(
    $input: CreateTournamentTeamInput!
    $condition: ModelTournamentTeamConditionInput
  ) {
    createTournamentTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      scoreID
      agegroup
      location
      event
      eventId
      city
      tournamentId
      players {
        items {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTournamentTeam = /* GraphQL */ `
  mutation UpdateTournamentTeam(
    $input: UpdateTournamentTeamInput!
    $condition: ModelTournamentTeamConditionInput
  ) {
    updateTournamentTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      scoreID
      agegroup
      location
      event
      eventId
      city
      tournamentId
      players {
        items {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTournamentTeam = /* GraphQL */ `
  mutation DeleteTournamentTeam(
    $input: DeleteTournamentTeamInput!
    $condition: ModelTournamentTeamConditionInput
  ) {
    deleteTournamentTeam(input: $input, condition: $condition) {
      id
      name
      imageURL
      scoreID
      agegroup
      location
      event
      eventId
      city
      tournamentId
      players {
        items {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTournamentLeague = /* GraphQL */ `
  mutation CreateTournamentLeague(
    $input: CreateTournamentLeagueInput!
    $condition: ModelTournamentLeagueConditionInput
  ) {
    createTournamentLeague(input: $input, condition: $condition) {
      id
      title
      date
      agegroup
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      allTeamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      allTeamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTournamentLeague = /* GraphQL */ `
  mutation UpdateTournamentLeague(
    $input: UpdateTournamentLeagueInput!
    $condition: ModelTournamentLeagueConditionInput
  ) {
    updateTournamentLeague(input: $input, condition: $condition) {
      id
      title
      date
      agegroup
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      allTeamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      allTeamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTournamentLeague = /* GraphQL */ `
  mutation DeleteTournamentLeague(
    $input: DeleteTournamentLeagueInput!
    $condition: ModelTournamentLeagueConditionInput
  ) {
    deleteTournamentLeague(input: $input, condition: $condition) {
      id
      title
      date
      agegroup
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      allTeamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      allTeamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWinner = /* GraphQL */ `
  mutation CreateWinner(
    $input: CreateWinnerInput!
    $condition: ModelWinnerConditionInput
  ) {
    createWinner(input: $input, condition: $condition) {
      id
      eventName
      eventId
      tournamentId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      totalRounds
      eventStatus
      rounds {
        items {
          id
          eventName
          eventId
          matches {
            items {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          winner {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          runnerUpList
          runnerUp {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          round
          eventStatus
          winnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWinner = /* GraphQL */ `
  mutation UpdateWinner(
    $input: UpdateWinnerInput!
    $condition: ModelWinnerConditionInput
  ) {
    updateWinner(input: $input, condition: $condition) {
      id
      eventName
      eventId
      tournamentId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      totalRounds
      eventStatus
      rounds {
        items {
          id
          eventName
          eventId
          matches {
            items {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          winner {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          runnerUpList
          runnerUp {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          round
          eventStatus
          winnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWinner = /* GraphQL */ `
  mutation DeleteWinner(
    $input: DeleteWinnerInput!
    $condition: ModelWinnerConditionInput
  ) {
    deleteWinner(input: $input, condition: $condition) {
      id
      eventName
      eventId
      tournamentId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      totalRounds
      eventStatus
      rounds {
        items {
          id
          eventName
          eventId
          matches {
            items {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          winner {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          runnerUpList
          runnerUp {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          round
          eventStatus
          winnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRounds = /* GraphQL */ `
  mutation CreateRounds(
    $input: CreateRoundsInput!
    $condition: ModelRoundsConditionInput
  ) {
    createRounds(input: $input, condition: $condition) {
      id
      eventName
      eventId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      round
      eventStatus
      winnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRounds = /* GraphQL */ `
  mutation UpdateRounds(
    $input: UpdateRoundsInput!
    $condition: ModelRoundsConditionInput
  ) {
    updateRounds(input: $input, condition: $condition) {
      id
      eventName
      eventId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      round
      eventStatus
      winnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRounds = /* GraphQL */ `
  mutation DeleteRounds(
    $input: DeleteRoundsInput!
    $condition: ModelRoundsConditionInput
  ) {
    deleteRounds(input: $input, condition: $condition) {
      id
      eventName
      eventId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      round
      eventStatus
      winnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeet = /* GraphQL */ `
  mutation CreateMeet(
    $input: CreateMeetInput!
    $condition: ModelMeetConditionInput
  ) {
    createMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeet = /* GraphQL */ `
  mutation UpdateMeet(
    $input: UpdateMeetInput!
    $condition: ModelMeetConditionInput
  ) {
    updateMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeet = /* GraphQL */ `
  mutation DeleteMeet(
    $input: DeleteMeetInput!
    $condition: ModelMeetConditionInput
  ) {
    deleteMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinicMeet = /* GraphQL */ `
  mutation CreateClinicMeet(
    $input: CreateClinicMeetInput!
    $condition: ModelClinicMeetConditionInput
  ) {
    createClinicMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinicMeet = /* GraphQL */ `
  mutation UpdateClinicMeet(
    $input: UpdateClinicMeetInput!
    $condition: ModelClinicMeetConditionInput
  ) {
    updateClinicMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinicMeet = /* GraphQL */ `
  mutation DeleteClinicMeet(
    $input: DeleteClinicMeetInput!
    $condition: ModelClinicMeetConditionInput
  ) {
    deleteClinicMeet(input: $input, condition: $condition) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinicUserMeet = /* GraphQL */ `
  mutation CreateClinicUserMeet(
    $input: CreateClinicUserMeetInput!
    $condition: ModelClinicUserMeetConditionInput
  ) {
    createClinicUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClinicUserMeet = /* GraphQL */ `
  mutation UpdateClinicUserMeet(
    $input: UpdateClinicUserMeetInput!
    $condition: ModelClinicUserMeetConditionInput
  ) {
    updateClinicUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClinicUserMeet = /* GraphQL */ `
  mutation DeleteClinicUserMeet(
    $input: DeleteClinicUserMeetInput!
    $condition: ModelClinicUserMeetConditionInput
  ) {
    deleteClinicUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      name
      description
      duration
      maxStudents
      price
      schedule {
        items {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      courseId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      name
      description
      duration
      maxStudents
      price
      schedule {
        items {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      courseId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      name
      description
      duration
      maxStudents
      price
      schedule {
        items {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      courseId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTutor = /* GraphQL */ `
  mutation CreateTutor(
    $input: CreateTutorInput!
    $condition: ModelTutorConditionInput
  ) {
    createTutor(input: $input, condition: $condition) {
      id
      name
      profile
      bio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTutor = /* GraphQL */ `
  mutation UpdateTutor(
    $input: UpdateTutorInput!
    $condition: ModelTutorConditionInput
  ) {
    updateTutor(input: $input, condition: $condition) {
      id
      name
      profile
      bio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTutor = /* GraphQL */ `
  mutation DeleteTutor(
    $input: DeleteTutorInput!
    $condition: ModelTutorConditionInput
  ) {
    deleteTutor(input: $input, condition: $condition) {
      id
      name
      profile
      bio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppointmentSchedule = /* GraphQL */ `
  mutation CreateAppointmentSchedule(
    $input: CreateAppointmentScheduleInput!
    $condition: ModelAppointmentScheduleConditionInput
  ) {
    createAppointmentSchedule(input: $input, condition: $condition) {
      id
      tutorId
      appointmentId
      slots {
        startTime
        endTime
        __typename
      }
      maxStudents
      excludeDays
      excludeSlots {
        date
        slots {
          startTime
          endTime
          __typename
        }
        __typename
      }
      daily
      days
      dates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppointmentSchedule = /* GraphQL */ `
  mutation UpdateAppointmentSchedule(
    $input: UpdateAppointmentScheduleInput!
    $condition: ModelAppointmentScheduleConditionInput
  ) {
    updateAppointmentSchedule(input: $input, condition: $condition) {
      id
      tutorId
      appointmentId
      slots {
        startTime
        endTime
        __typename
      }
      maxStudents
      excludeDays
      excludeSlots {
        date
        slots {
          startTime
          endTime
          __typename
        }
        __typename
      }
      daily
      days
      dates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppointmentSchedule = /* GraphQL */ `
  mutation DeleteAppointmentSchedule(
    $input: DeleteAppointmentScheduleInput!
    $condition: ModelAppointmentScheduleConditionInput
  ) {
    deleteAppointmentSchedule(input: $input, condition: $condition) {
      id
      tutorId
      appointmentId
      slots {
        startTime
        endTime
        __typename
      }
      maxStudents
      excludeDays
      excludeSlots {
        date
        slots {
          startTime
          endTime
          __typename
        }
        __typename
      }
      daily
      days
      dates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserMeet = /* GraphQL */ `
  mutation CreateUserMeet(
    $input: CreateUserMeetInput!
    $condition: ModelUserMeetConditionInput
  ) {
    createUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      date
      appointmentId
      slot {
        startTime
        endTime
        __typename
      }
      courseID
      appointmentScheduleId
      type
      typeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserMeet = /* GraphQL */ `
  mutation UpdateUserMeet(
    $input: UpdateUserMeetInput!
    $condition: ModelUserMeetConditionInput
  ) {
    updateUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      date
      appointmentId
      slot {
        startTime
        endTime
        __typename
      }
      courseID
      appointmentScheduleId
      type
      typeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserMeet = /* GraphQL */ `
  mutation DeleteUserMeet(
    $input: DeleteUserMeetInput!
    $condition: ModelUserMeetConditionInput
  ) {
    deleteUserMeet(input: $input, condition: $condition) {
      id
      meetId
      email
      userId
      date
      appointmentId
      slot {
        startTime
        endTime
        __typename
      }
      courseID
      appointmentScheduleId
      type
      typeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivities = /* GraphQL */ `
  mutation CreateActivities(
    $input: CreateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    createActivities(input: $input, condition: $condition) {
      id
      type
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivities = /* GraphQL */ `
  mutation UpdateActivities(
    $input: UpdateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    updateActivities(input: $input, condition: $condition) {
      id
      type
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivities = /* GraphQL */ `
  mutation DeleteActivities(
    $input: DeleteActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    deleteActivities(input: $input, condition: $condition) {
      id
      type
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlayerScore = /* GraphQL */ `
  mutation CreatePlayerScore(
    $input: CreatePlayerScoreInput!
    $condition: ModelPlayerScoreConditionInput
  ) {
    createPlayerScore(input: $input, condition: $condition) {
      id
      tournamentId
      scoreId
      tournament {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      score {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlayerScore = /* GraphQL */ `
  mutation UpdatePlayerScore(
    $input: UpdatePlayerScoreInput!
    $condition: ModelPlayerScoreConditionInput
  ) {
    updatePlayerScore(input: $input, condition: $condition) {
      id
      tournamentId
      scoreId
      tournament {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      score {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlayerScore = /* GraphQL */ `
  mutation DeletePlayerScore(
    $input: DeletePlayerScoreInput!
    $condition: ModelPlayerScoreConditionInput
  ) {
    deletePlayerScore(input: $input, condition: $condition) {
      id
      tournamentId
      scoreId
      tournament {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      score {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
