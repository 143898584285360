import Advertisement from ".";
import useFetchAdvertisement from "../../hooks/getAdvertisementByType";
import LoaderSkeleton from "./ADSKELETON";
import placeholderImage from "./advertisement.png";

const AdvertisementEvent = ({ one = false, length, index = 0 }) => {
  const { advertisement, loading } = useFetchAdvertisement();
  console.log("AdvertisementEvent", index, advertisement);
  let ind = index;
  if (!one) {
    if (length >= advertisement.length) {
      ind = advertisement.length;
    }
  }

  return (
    <>
      {loading ? (
        // Placeholder for image while loading
        <LoaderSkeleton />
      ) : ind < advertisement?.length && advertisement.length > 0 ? (
        // !one ? (
        //   ind < advertisement.length - 1 && (
        //     <Advertisement
        //       ad={advertisement[ind]}
        //       index={ind}
        //     />
        //   )
        // ) : (
        // advertisement.map((ad, index) => (
        <Advertisement ad={advertisement[ind]} index={0} />
      ) : (
        // ))
        // )
        <img
          className="w-screen h-[240px] object-cover"
          src={placeholderImage}
          alt="AdvertisementImage"
        />
      )}
    </>
  );
};

export default AdvertisementEvent;
