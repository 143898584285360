import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import TypoBu from "../../Components/TypoBu";

import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { SiInstagram } from "react-icons/si";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import validator from "validator";
import useSubscribeNewsLetter from "../../hooks/subscribeNewsLetter";
import { ToastContainer,toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import {
  getAllEvents,
} from "../../redux/actions/EventsAction";
import UpComingSkeleton from "../../Components/Skeleton/UpComingEventsSkeleton";
import HomeUpComingEvents from "../LeagueHome/HomeUpComingEvents";
import useFetchAdvertisement from "../../hooks/getAdvertisementByType";
import ADHOME from "../../Components/Advertisement/HOMEADVERTISEMENT";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const Home = () => {
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [emailError, setEmailError] = useState(""); // State to store the email validation error
  const { subscribeNewsLetter, loading } = useSubscribeNewsLetter();
  useEffect(() => {
    // if (error) {
    //   dispatch(clearEventsErrors());
    // }
    dispatch(getAllEvents()); // Check if this is an action creator that fetches news
  }, []);

  const closeNewsletterPopup = () => {
    setShowNewsletterPopup(false);
    document.body.style.overflow = 'visible';
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(""); // Clear the email error when the email is changed

    // Email validation logic (you can customize this as needed)
    if (!newEmail) {
      setEmailError("Email is required");
    } else if (!validator.isEmail(newEmail)) {
      setEmailError("Invalid email format");
    }
  };
  const handleJoinClick = async () => {
    if (!email) {
      setEmailError("Email is required");
      return; // Prevent further action if email is empty
    }
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
    toast.success("Subscribed")
    // Implement logic to subscribe the user
    await subscribeNewsLetter(email);
    setShowNewsletterPopup(false);
    setSubscribed(true);
    // If successful, setSubscribed(true) and close the popup
  };
  useEffect(() => {
    // Disable scrolling when the newsletter popup is displayed
    if (showNewsletterPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scrolling when the popup is closed
      document.body.style.overflow = 'visible';
    }
  }, [showNewsletterPopup]);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <div className="bg-[#121011] text-white">
      <ToastContainer />
      {showNewsletterPopup && !subscribed && (
        <div className="fixed inset-0 z-[1000] px-[25px] flex items-center justify-center  bg-black bg-opacity-50">
          <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg max-w-xl w-full ">
            <div className="flex justify-end">
              <GrFormClose
                size={30}
                onClick={closeNewsletterPopup}
                className="p-[2px] cursor-pointer  text-black"
              />
            </div>
            <div className="text-xs  text-black mb-2 text-center">
              Subscribe to our
            </div>
            <div className="text-red-500  tracking-widest text-lg mb-2 text-center">
              Newsletter
            </div>
            <div className="text-xs  text-black mb-2 text-center">
              To be updated with all the latest trends and products
            </div>
            <div className="flex sm:flex-row flex-col lg:-ml-[7rem] sm:items-center  mb-4">
              <div className="w-64 h-32 mb-10">
                <img src="/images/Bigball.png" alt="Big Ball" className="" />
              </div>
              <div className="flex h-12">
                <div className="flex-grow">
                  <input
                    type="email"
                    placeholder="Email"
                    className={`border rounded-md text-black p-2 mr-2 w-full h-full ${
                      emailError ? "border-red-500" : ""
                    }`}
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <div className="mt-2 text-red-500 text-sm">
                      {emailError}
                    </div>
                  )}
                </div>

                <button
                  onClick={handleJoinClick}
                  className="bg-[#fec749] text-black uppercase px-8 whitespace-nowrap  ml-2 rounded-md"
                >
                  Join
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="home-banner text-4xl lg:text-6xl md:justify-between  md:flex">
        <div className="flex flex-col w-fit my-auto md:justify-between ">
          <div className="lg:pl-40 p-5 md:pl-20 pl-2">
            <span className=" italic font-black">
              <span>&quot;</span>
              <span className=" md:pl-10 sm:pl-2">
                <div> YOU DON'T</div>
                <div> DESERVE TO</div>
                <div>
                  {" "}
                  WIN!{" "}
                  <span className="text-2xl text-red-500  whitespace-nowrap  md:pl-20">
                    IF YOU DON'T PRACTICE
                  </span>
                </div>
              </span>
            </span>
            <div className="flex flex-col md:flex-row justify-center items-center mt-5 gap-10">
              <NavLink
                to="/signupleague"
                className="text-xl text-[#fec749] uppercase text-center  border-2 p-2 border-[#fec749] rounded-md w-60 whitespace-nowrap"
              >
                join our team
              </NavLink>
              <NavLink
                to="/register"
                className="text-xl uppercase w-60 text-center mr-2 border-2 p-2 bg-[#fec749] text-black border-[#fec749] rounded-md whitespace-nowrap"
              >
                register
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex  md:justify-end mt-5 justify-center items-center">
          <div className="text-gray-400 flex md:flex-col   text-xl gap-4">
            <NavLink>
              <ImFacebook className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <IoLogoTwitter className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <AiOutlineGooglePlus className="mx-2 inline" />
            </NavLink>
            <NavLink>
              <SiInstagram className="mx-2 inline" />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="py-20">
        <img
          src="/images/logo_breaking2.png"
          alt="logo_breaking"
          className="mx-auto block w-96"
        />
      </div>
      <Teams />
   
      <div className="md:p-20 p-4 py-20 bg-[#252B2F]">
        {/* <div className="flex pt-10 pb-40">
          <div>
            <span className="text-4xl font-bold">28</span>
            <span className="text-2xl"> May</span>
            <br />
            <span className="text-yellow-400 text-xl">05:00 PM</span>
            <br />
            <span className="text-xl">TUESDAY</span>
          </div>
          <div className="px-10">
            <img alt="ball" src="/images/ball.png" />
          </div>
          <div>
            <div className="w-32   inline-block mt-24">
              <div className=" h-52  bg-white -rotate-45 transform origin-top-right"></div>
            </div>
          </div>
          <div className="px-10 flex-1 bg-white text-black py-10 border-white ">
            <span className="text-2xl font-bold py-4">GAME TITLE</span> <br />
            <VscLocation className="inline text-[#D70D15]" />
            <span>Location</span>
            <br />
            <div className="flex justify-between items-center py-8">
              <img
                alt="soncis"
                src="/images/match_sonics.png"
                className="w-2/5"
              />
              <span>VS</span>
              <img
                alt="match_3x3"
                src="/images/match_3x3.png"
                className="w-2/5"
              />
            </div>
            <div className="flex float-right">
              <FaShareAltSquare className="inline text-5xl mr-4" />
              <button className="bg-[#FEC749] rounded-md font-bold px-10">
                BOOK
              </button>
            </div>
          </div>
        </div> */}
       
       <HomeUpComingEvents/>
       </div>
      <div className="md:p-20  md:py-10 bg-[#181B22]">
  <AdvertisementOne index={0}/>
      </div>
      <div className="md:p-20 p-4 bg-[#181B22] flex flex-col gap-20 py-20">
        {/* <Advertisement /> */}
        <TypoBu
          item={{
            text: "shop",
            text_ssize: "text-3xl",
            text_bsize: "text-4xl",
            border: true,
            button: false,
          }}
        />
        <Shop />
        {/* <RenderShop /> */}
        <div className="text-center">
          <Link
            to="/store"
            className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
          >
            VIEW ALL
          </Link>
        </div>
      </div>
    </div>
  );
};



export default Home;
