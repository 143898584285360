import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { CiCircleMore } from "react-icons/ci";
import SignTour from "../../Components/SignTour";
import validator from "validator";
import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../App";
import { API } from "aws-amplify";
import useGetAllTeams from "../../hooks/getTeamsLists";
import useGetAllCity from "../../hooks/getCityList";
import { useSelector, useDispatch } from "react-redux";

import useCreateAddress from "../../hooks/createAddress";
import useAuthInfo from "../../hooks/getUser";
import useCreateCart from "../../hooks/createCart";
import { login } from "../../redux/actions/UserAction";

const SignUp = () => {
  const { teamslist } = useGetAllTeams();
  const {createAddress,loading:addressLoading}=useCreateAddress;
  const dispatch = useDispatch();
  const { citylist } = useGetAllCity();
  const {
    error: error,
    user,
    isAuthenticated,
  } = useSelector((state) => {
    return state.user;
  });
  const [loading, setLoading] = useState(false);
  const { createUserCart } = useCreateCart();
  let navigate = useNavigate();
  const [userId, setUserId] = useState(""); // State to store user ID

  // const { dispatch } = React.useContext(AuthContext);
  const routeChange = () => {
    let path = "/login";
    navigate(path);
  };
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    birthdate: null,
    gender: "male",
    phone_number: '+1',
    password: "",
    confirmPassword: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [city, setCity] = useState([
    {
      cityId: "",
      name: "",
    },
  ]);
  const [teams, setTeams] = useState([
    {
      teamId: "",
      name: "",
    },
  ]);

  useEffect(() => {
    // getData();
    if (isAuthenticated) {
      navigate("/");
    }
    // fetch list of cities
  }, []);
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  const onChangeData = (name, value) => {
    setUserDetails((prevDetails) => {
      if (name === 'phone_number') {
        // Ensure the phone number always starts with +1
        if (!value.startsWith('+1')) {
          value = '+1' + value.replace(/^(\+1)?/, '');
        }
      }
  
      return {
        ...prevDetails,
        [name]: value,
      };
    });
  };
  
  const validatePhoneNumber = (e) => {
    var number = e.target.value;
    if (validator.isMobilePhone(number) || number === "") {
      setPhoneError("");
    } else {
      setPhoneError("Enter valid Phone Number!");
    }
  };
  const validatePassword = (e) => {
    var password = e.target.value;

    if (
      password === document.getElementById("password").value ||
      password === ""
    ) {
      setPasswordError("");
    } else {
      setPasswordError("Enter Valid Password");
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [signCode, setSignCode] = useState({
    statusCode: "",
    msg: "",
    code: "",
  });

  // State - Verification Code sent to email
  const [verifying, setVerifying] = useState(false);
  const [verificationErrorMessage, setverificationErrorMessage] = useState("");

  const signUp = async () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    Auth.signUp({
      username: userDetails.email.toLowerCase().trim(),
      password: userDetails.password.trim(),
      attributes: {
        email: userDetails.email.trim(),
        // "custom:team": document.getElementById("team").value,
        birthdate: userDetails.birthdate,
        // "custom:leagues": document.getElementById("leagues").value,
        phone_number: userDetails.phone_number,
        gender: userDetails.gender,

        name: userDetails.name,
      },
    })
      .then((response) => {
        setVerifying(true);
        console.log("responseCreate", response);

        createAddress(response.userSub)
        setSignCode({ statusCode: "200", msg: "Sign up successful" });
        setUserId(response.userSub);
        setShowModal(true);
      })
      .catch((error) => {
        setSignCode({
          statusCode: "400",
          msg: error?.message,
          code: error?.code,
        });
        setShowModal(true);
        console.log("Sign up error - ", error);
        console.log("Sign up error code - ", error?.code);
        console.log("Sign up error msg - ", error?.message);
      });

    // let str = response?.request.responseText.slice(23);
    // let newstr = str.slice(0, str.length - 2);
    // let code = response.request.responseText.slice(8, 11);
    // await setSignCode({ code: code, msg: newstr });
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      signUp();
    }
  };

  const closeVerifing = () => {
    // signIn()
    setVerifying(false);
  };

  const signIn = (
    email = document.getElementById("email").value,
    password = document.getElementById("password").value
  ) => {
    Auth.signIn(email.trim(), password)
      .then((response) => {
        dispatch({
          type: "LOGIN",
          email: email,
        });
        dispatch(login(email, password, response));

        createUserCart(response.attributes.sub);

        navigate("/");
        // Fetch user ID on component mount
      })
      .catch((error) => {
        setSignCode({
          statusCode: "400",
          msg: error?.message,
          code: error?.code,
        });
        setShowModal(true);
        console.error("Sign in error - ", error);
      });
  };

  //
  const verifyEmail = () => {
    const userInputCode = document.getElementById("verification_code")?.value;
    const email = document.getElementById("email").value;
    Auth.confirmSignUp(email, userInputCode)
      .then((response) => {
        setSignCode({ statusCode: "200", msg: "Email verified successfully" });
        setShowModal(true);
        console.log("Verify email response - ", response);
        setVerifying(false);
        signIn(email);
      })
      .catch((error) => {
        setSignCode({
          statusCode: "400",
          msg: error?.message,
          code: error?.code,
        });
        if (error?.code === "CodeMismatchException") {
          setverificationErrorMessage(error.message);
        } else {
          setShowModal(true);
          setVerifying(false);
        }
        console.error("Verify email error - ", error);
      });
  };

  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {signCode.statusCode === "200" ? "Success" : "Fail"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {signCode.msg}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      let path = "/";
                      navigate(path);
                    }}
                  >
                    Go to the Home Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* Email Verification Modal */}
      {verifying && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl font-semibold">Verify Email</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setVerifying(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 pb-0 flex-auto">
                  <p className="my-2 text-slate-500 text-lg leading-relaxed">
                    Enter the 6-digit verification code sent to your email{" "}
                    {document.getElementById("email")?.value}
                    <input
                      id="verification_code"
                      type="text"
                      className="block mx-auto h-10 p-2 border-2 border-slate-200 rounded-md mt-6"
                    />
                    <span className="my-2 text-orange-500 text-sm leading-relaxed">
                      {verificationErrorMessage}
                    </span>
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={closeVerifing}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      verifyEmail();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {/* End Email Verification Modal */}

      <div className="sm:ml-4 md:flex">
        <div className="md:w-3/5 p-8 mt-10 relative mb-20">
          <div className="text-8xl text-[#F1F1F1] font-bold relative z-10">
            <div>SIGN</div>
            <div>UP</div>
          </div>
          <img
            className="absolute top-10 md:left-20 sm:left-20 z-20"
            src="/images/Bigball.png"
            alt="Bigball"
          />
        </div>

        <div className="w-full  md:mr-8 sm:mt-20 px-2">
          <div className="py-2 text-4xl text-red-500 md:tracking-widest">
            <span className="text-[#D70D15] text-5xl font-bold">|</span>
            Register
          </div>

          <div>
            <div className="justify-between md:flex-row flex-col  flex">
              <div className="md:w-half w-full md:mr-4">
                <div className="mt-4">
                  <h5 className="">Name</h5>
                  <input
                    className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    placeholder="Name"
                    id="name"
                    value={userDetails.name}
                    onChange={(e) => onChangeData("name", e.target.value)}
                  ></input>
                </div>
                <div className="mt-4">
                  <h5 className="">D.O.B</h5>
                  <input
                    className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    placeholder="date of birth"
                    type="date"
                    id="dob"
                    value={userDetails.birthdate}
                    onChange={(e) => onChangeData("birthdate", e.target.value)}
                  ></input>
                </div>

                <div className="mt-4 flex flex-col gap-2 items-start">
                  <h5>Gender</h5>
                  <div className="flex  gap-2 items-start">
                    <label className="flex gap-2 items-center">
                      Male
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={userDetails.gender === "male"}
                        onChange={(e) => onChangeData("gender", e.target.value)}
                      />
                    </label>
                    <label className="flex gap-2 items-center">
                      Female
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={userDetails.gender === "female"}
                        onChange={(e) => onChangeData("gender", e.target.value)}
                      />
                    </label>
                    {/* <label>
                      Other
                      <input
                        type="radio"
                        name="gender"
                        value="other"
                        checked={userDetails.gender === "other"}
                        onChange={(e) => onChangeData("gender", e.target.value)}
                      />
                    </label> */}
                  </div>
                  <div className="mt-4">
                    <h5 className="">Age</h5>
                    <select
                      name="leagues"
                      id="age"
                      onKeyPress={handleEnterPress}
                      className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    >
                      <option value="default" disabled>
                        Age
                      </option>
                      {ages.map((item, index) => {
                        return <option key={index}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:w-half w-full">
                <div className="mt-4">
                  <h5 className="">Email</h5>
                  <input
                    className=" border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    placeholder="Email"
                    type="Email"
                    id="email"
                    onKeyPress={handleEnterPress}
                    value={userDetails.email}
                    onChange={(e) => onChangeData("email", e.target.value)}
                  ></input>
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {emailError}
                  </span>
                </div>
                <div className="mt-4">
                  <h5 className="">Phone</h5>
                  <input
                    className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    placeholder="+1"
                    onKeyPress={handleEnterPress}
                    value={userDetails.phone_number}
                    onChange={(e) =>
                      onChangeData("phone_number", e.target.value)
                    }
                    id="phone"
                  ></input>
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {phoneError}
                  </span>
                </div>
                <div className="mt-4">
                  <h5 className="">Password</h5>
                  <input
                    className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                    placeholder=""
                    onKeyPress={handleEnterPress}
                    type="password"
                    id="password"
                    value={userDetails.password}
                    onChange={(e) => onChangeData("password", e.target.value)}
                  ></input>
                </div>
                <div className="mt-4">
                  <h5 className="">Confirm Password</h5>
                  <input
                    className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full"
                    type="password"
                    placeholder=""
                    onKeyPress={handleEnterPress}
                    value={userDetails.confirmPassword}
                    onChange={(e) =>
                      onChangeData("confirmPassword", e.target.value)
                    }
                  ></input>
                </div>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {passwordError}
                </span>
                <div className="text-center">
                  <div className="m-6">
                    <button
                      onKeyPress={handleEnterPress}
                      className="bg-[#fec749] px-20 py-2 whitespace-nowrap"
                      onClick={signUp}
                    >
                      Sign Up
                    </button>
                  </div>
                  <div>
                    <span>
                      Already have an account?
                      <button
                        onClick={() => navigate("/login")}
                        className="font-bold ml-2"
                      >
                        Log in
                      </button>
                    </span>
                  </div>
                  <div className="flex-grow">
                    <div className="flex items-center">
                      <div className="flex-grow border-t border-gray-300"></div>
                      <div className="mx-4 text-gray-500">or</div>
                      <div className="flex-grow border-t border-gray-300"></div>
                    </div>
                  </div>
                  <div className="text-4xl">
                    <BsFacebook className="inline text-[#1877F2] mr-4" />
                    <FcGoogle className="inline mr-4" />
                    <CiCircleMore className="inline text-[#8C8C8C]" />
                  </div>
                  <div>
                    <p>
                      By logging in or creating an account, you agree to
                      Breaking Boinners
                    </p>
                    <p>
                      {" "}
                      <a href="#" className="underline font-bold">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a href="#" className="underline font-bold">
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SignTour />
      </div>
    </div>
  );
};
export default SignUp;

const ages = ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"];
